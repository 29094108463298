/**
* Represents a book.
* @constructor
* @rortlieb {string} title - The title of the book.
* @rortlieb {string} author - The author of the book.
*/

//import { Fancybox } from '../vendor/fancybox/fancybox.esm.js';

//window.Fancybox = Fancybox;

const preloader = document.getElementById('preloader');
  if (preloader) {
    window.addEventListener('load', () => {
      preloader.remove()
    });
  }

document.addEventListener("DOMContentLoaded", function () {

  let loadtheme = get('spaneldump', 'default_theme');

  if (loadtheme) {

    document.documentElement.setAttribute('data-bs-theme', loadtheme);

  } else {

    loadtheme = "light";

    document.documentElement.setAttribute('data-bs-theme', loadtheme);

    update('spaneldump', 'default_theme', loadtheme);

  }

  if (loadtheme === "light") {

    var element = document.getElementById('moon');

    if (element) {

      document.getElementById('moon').classList.toggle("d-none");

    }

  } else if (loadtheme === "dark") {

    var elmoon = document.getElementById('moon');

    if (elmoon) {

      document.getElementById('sun').classList.toggle("d-none");

    }

  }


});

/* Local Storage */

function set(key, value) {

  localStorage.setItem(key, JSON.stringify(value));

}

function get(name, key) {

  let existing = localStorage.getItem(name, key);
  let res = JSON.parse(existing);

  if (res) {

    return res[key];

  }
}

function update(name, key, value) {

  // Get the existing data
  let existing = localStorage.getItem(name);

  // If no existing data, create an array
  // Otherwise, convert the localStorage string to an array
  existing = existing ? JSON.parse(existing) : {};

  // Add new data to localStorage Array
  existing[key] = value;

  // Save back to localStorage
  localStorage.setItem(name, JSON.stringify(existing));

}

function storetheme(whatever) {

  update('spaneldump', 'default_theme', whatever);

  document.documentElement.setAttribute('data-bs-theme', whatever);

}

function scroll_to_to() {

  var nav = document.getElementById('navbar');
  var totop = document.getElementById('to_top');

  if (totop) {

    var x = window.matchMedia("(max-width: 424px)");

    window.addEventListener('scroll', function (e) {

      e.preventDefault();

      const lastPosition = window.scrollY;

      if (lastPosition > 50) {

        nav.classList.remove("py-4");
        totop.classList.remove("tw-hidden");

        if (x.matches) {
          totop.classList.add("tw-hidden");
        }

      } else {

        nav.classList.add("py-4");
        totop.classList.add("tw-hidden");

      }

    });

    totop.addEventListener("click", function (e) {

      e.preventDefault();

      window.scrollTo(0, 0);

    });

  } else {

    return;

  }

}

scroll_to_to();

function modal() {

  const emodal = document.querySelectorAll(".setmodal");

  for (let i = 0; i < emodal.length; ++i) {

    emodal[i].addEventListener("click", get_content);

  }

}

function get_content(get) {

  const callbt = document.getElementById(get);

  const whatever = callbt.getAttribute('data-bs-whatever');

  const myModal = new bootstrap.Modal(document.getElementById('modal_lg'));

  const modalBody = document.querySelector('.modal-body');

  const modalTitle = document.querySelector('.modal-title')

  modalTitle.innerHTML = 'Modal PHP';

  myModal.show();

  // requisição com o XMLHttpRequest
  const request = new XMLHttpRequest()

  request.open('GET', 'http://www.spanelappdump.dev/spanel/' + whatever)

  request.onload = function () {

    modalBody.innerHTML = this.responseText;

  }

  request.onerror = function () {

    console.log('erro ao executar a requisição');

  }

  request.send()

}

/* SWTICH THEME
*
*/

function cliks() {

  let sun = document.getElementById('sun');

  if (sun) {

    sun.addEventListener("click", swtich_them);

    let moon = document.getElementById('moon');

    moon.addEventListener("click", swtich_them);

  } else {

    return;

  }



}

cliks();

function swtich_them() {

  let whatever = this.getAttribute('data-bs-whatever');

  switch (whatever) {
    case 'light':
      hide(this);
      show('moon');
      break;
    case 'dark':
      hide(this);
      show('sun');
      break;
    default:
    // code block
  }

  document.documentElement.setAttribute('data-bs-theme', whatever);

  update('spaneldump', 'default_theme', whatever);

}

function hide(target) {

  target.classList.toggle("d-none");

}

function show(target) {

  let show = document.getElementById(target);

  show.classList.toggle("d-none");

}

/*****************
 * Parte two
 ****************/

function loadModalContent(bt) {
  // Create a new XMLHttpRequest object

  const callbt = document.getElementById(bt);

  var get = JSON.parse(callbt.dataset.whatever);

  var title = get['title'];

  var action = get['action'];

  var queryString = build_query(get);

  var xhr = new XMLHttpRequest();

  // Configure it: GET-request for the URL /api

  xhr.open('GET', 'https://www.spanelappdump.dev/spanel/serverside/' + action + '.php?' + queryString, true);

  // Set up the callback function to handle the response

  xhr.onreadystatechange = function () {

    if (xhr.readyState == 4 && xhr.status == 200) {
      // Parse and log the response
      //var response = JSON.parse(xhr.responseText);
      var response = xhr.responseText;

      document.getElementById("eModalLabel").innerHTML = title;

      document.getElementById("modal-body-content").innerHTML = response;

      //console.log(response);

    } else if (xhr.readyState == 4) {

      console.error('Error: ' + xhr.status);

    }
  };

  // Send the request
  xhr.send();
}

var build_query = function (obj) {

  let query = '';

  let i = 1;

  Object.keys(obj).forEach(function (key) {

    if (i > 1) {

      query += "&";

    }

    query += key + '=' + obj[key];

    i++;

  })

  //console.log(query);

  return query;

}

/* SWIPER */

function swiper(id) {

  const swiper = new Swiper(id, {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slidesPerView: 4,

    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      375: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 46
      },
    },

  });


}

const setgal = function() {

  const emodal = document.querySelectorAll(".setgal");

  for (let i = 0; i < emodal.length; ++i) {

      emodal[i].addEventListener("click", get_content_list);

  }

}

const get_content_list = function() {

  const whatever = this.getAttribute('data-bs-whatever');

  const httpRequest = new XMLHttpRequest();
  httpRequest.open('GET', whatever, true);
  httpRequest.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
  httpRequest.send();
  httpRequest.onreadystatechange = function () {

      if (httpRequest.readyState != 4 || httpRequest.status != 200) return;

      let results = httpRequest.responseText;

      let images = Function("return " + results)();

      Fancybox.show(images, {

          Carousel: {
              transition: "slide"
          },

          startIndex: -1,
          Thumbs: false,
          showClass: "f-fadeIn",
          Slideshow: {
              playOnStart: true,
          },
      });

  }

}

var sitemodal = function () {

  const siteModal = document.getElementById('sitemodal');
  siteModal.addEventListener('show.bs.modal', event => {

      const button = event.relatedTarget;
      const recipient = button.getAttribute('data-bs-whatever');

      const httpRequest = new XMLHttpRequest();
      httpRequest.open('GET', recipient, true);
      httpRequest.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
      httpRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      httpRequest.send();
      httpRequest.onreadystatechange = function () {

          if (httpRequest.readyState != 4 || httpRequest.status != 200) return;

          //const modalTitle = siteModal.querySelector('.modal-title')
          const modalBodyInput = siteModal.querySelector('.modal-body')

          //modalTitle.textContent = `New message to ${recipient}`
          modalBodyInput.innerHTML = httpRequest.responseText;


      }
  });

}

  var egourl = function () {

      var gourl = document.getElementsByClassName("gourl");

      var myFunction = function () {
          var attribute = this.getAttribute("data-bs-whatever");

          window.location.href = attribute;
      };

      for (var i = 0; i < gourl.length; i++) {
          gourl[i].addEventListener('click', myFunction, false);
      }

  }

  var simple_swiper = function(setclass){

    var swiper = new Swiper(setclass, {
      slidesPerView: 1,
      autoplay: {
          delay: 3000,
      },
      loop: true,
  });

  }

var out_frame = function () {

  const siteModal = document.getElementById('sitemodal');

  if (siteModal) {
    
    siteModal.addEventListener('hide.bs.modal', () => {

      window.location.reload();

    });

  }



}

  out_frame();

  AOS.init({
    duration: 500,
    easing: 'ease-in-out',
    once: true,
    mirror: false,
    disable: 'mobile'    
  });

  var get_session = function() {
    
    const httpRequest = new XMLHttpRequest();
    httpRequest.open('GET', 'painel-do-aluno/serverside/js-get-session.php?action=get_session', true);
    httpRequest.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    httpRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    httpRequest.send();
    httpRequest.onreadystatechange = function () {
          
    if (httpRequest.readyState != 4 || httpRequest.status != 200) return;
        
        session = httpRequest.responseText;
        
        //console.log(session);

        update('spaneldump', 'APP_TOKEN', session);
        
/*         if(!getCookie('APP_TOKEN')){
        
        setCookie('APP_TOKEN', session, 2);
            
        } */
        
        var session;
        

        
    }
    
}

get_session();